import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';

// Styled Components
const ChatbotContainer = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.primary};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
    z-index: 1000; /* Ensure it's on top */
`;

const ChatbotDialog = styled.div`
    position: fixed;
    bottom: 100px;
    right: 20px;
    width: 300px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.card};
    color: ${({ theme }) => theme.text_primary};
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
    padding: 20px;
    display: ${({ visible }) => (visible ? 'block' : 'none')};
    transition: all 0.3s ease;
    z-index: 999; /* Below the ChatbotContainer but still on top */
`;

const Message = styled.div`
    margin-bottom: 10px;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.primary};
    margin-bottom: 10px;
`;

const Option = styled.div`
    background-color: ${({ theme }) => theme.primary};
    color: white;
    padding: 10px;
    margin: 5px 0;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    &:hover {
        background-color: ${({ theme }) => theme.primary + 99};
    }
`;

const Chatbot = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [step, setStep] = useState(1);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const inputRef = useRef(null); // Create a ref for the input

    const handleNext = () => {
        if (step === 1 && name.trim() === '') return; // Prevent advancing if name is empty
        if (step === 2 && email.trim() === '') return; // Prevent advancing if email is empty

        if (step === 3) {
            const subject = "Chatbot User Selection";
            const text = `User selected: ${(email ? "To get connected" : "To check")}`;
            sendEmail(subject, text, name, email);
        }

        setStep(step + 1);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleNext();
        }
    };

    const toggleChatbot = () => {
        setIsOpen(!isOpen);
    };

    // Use EmailJS to send the email
    const sendEmail = () => {
        const templateParams = {
            from_name: name,
            from_email: email,
            message: "Chatbot user interaction",
        };

        emailjs.send('service_h23icpb', 'template_8yozjfl', templateParams, 'KHD8X0X50HvMZvh3r')
            .then(() => {
                setStep(4); // Advance to thank you message
            })
            .catch((error) => {
                console.error('Error sending email:', error);
            });
    };

    useEffect(() => {
        if (isOpen) {
            inputRef.current?.focus(); // Automatically focus the input field when opened
        }
    }, [isOpen, step]); // Focus on input when chatbot is opened or step changes

    return (
        <>
            {/* Floating Chatbot Icon */}
            <ChatbotContainer onClick={toggleChatbot}>
                💬
            </ChatbotContainer>

            {/* Chatbot Dialog */}
            <ChatbotDialog visible={isOpen}>
                {step === 1 && (
                    <>
                        <Message>Welcome to the portfolio!</Message>
                        <Message>What’s your name?</Message>
                        <Input 
                            type="text" 
                            name="name"
                            value={name} 
                            onChange={handleNameChange} 
                            onKeyDown={handleKeyDown}
                            ref={inputRef} // Set the ref for the input
                            placeholder="Enter your name" 
                        />
                        <Option onClick={handleNext}>Next</Option>
                    </>
                )}
                {step === 2 && (
                    <>
                        <Message>Nice to meet you, {name}!</Message>
                        <Message>What's your email?</Message>
                        <Input 
                            type="email" 
                            name="email"
                            value={email} 
                            onChange={handleEmailChange} 
                            onKeyDown={handleKeyDown}
                            ref={inputRef} // Set the ref for the input
                            placeholder="Enter your email" 
                        />
                        <Option onClick={handleNext}>Next</Option>
                    </>
                )}
                {step === 3 && (
                    <>
                        <Message>What brings you here today?</Message>
                        <Option onClick={handleNext}>I want to connect with you</Option>
                        <Option onClick={handleNext}>I'm just exploring</Option>
                    </>
                )}
                {step === 4 && (
                    <>
                        <Message>Thank you! Soon I will get in touch with you.</Message>
                    </>
                )}
                {step === 5 && (
                    <>
                        <Message>Thank you for visiting!</Message>
                    </>
                )}
            </ChatbotDialog>
        </>
    );
};

export default Chatbot;
